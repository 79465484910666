import { combineReducers, createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import { aPaymentMethodUpdatedSuccessfully } from '@wix/bi-logger-my-subscriptions/v2';
import { Thunk, ThunkApiConfig } from '../../types/thunk-extra';
import { getEditorSubscriptionDetailsDemo, subscriptionsBassFixture } from '../../editorProductionMocks';
import { getCancelConfirmModalSubscriptionId, getDetails, getSubscriptionById, isFeatureEnabled } from './selectors';
import {
  PAID_PLANS_APP_DEF_ID,
  STORES_APP_DEF_ID,
  TIME_UNTIL_SUCCESS_TOAST_DISAPPEARS,
  TIME_UNTIL_ERROR_TOAST_DISAPPEARS,
  UpmFlowStatus,
} from './constants';
import { Subscription, Action, SubscriptionCharge } from '@wix/ambassador-billing-v1-subscription/types';
import { Balance } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { Subscription as StoresSubscription } from '@wix/ambassador-wix-ecommerce-subscriptions/types';
import {
  mySubscriptionsCancelSubscriptionConfirmed,
  mySubscriptionsShowDetails,
} from '@wix/bi-logger-subscriptions-bm/v2';
import { Interactions } from '../../types/interactions';
import { hasAnyDiscount, isRecurringSubscription } from './domainUtils';
import {
  customerAllowedActions,
  customerCancelSubscription,
  customerQuerySubscriptions,
  customerTurnOffSubscriptionAutoRenewal,
  customerListUpcomingCharges,
  customerUpdateSubscriptionPaymentMethod,
} from '@wix/ambassador-billing-v1-subscription/http';
import { Experiments } from '../../Experiments';
import { ExperimentsBag, IHttpClient } from '@wix/yoshi-flow-editor';
import { getSavedPaymentMethodDetailsByAgreementId } from '@wix/ambassador-cashier-pay-v2-payment-method/http';
import { SavedPaymentMethodDetails } from '@wix/ambassador-cashier-pay-v2-payment-method/types';
import { getPaymentMethodDisplayName } from '../../utils/displayUtils';

type LanguageState = string;
const languageSlice = createSlice({
  name: 'language',
  initialState: 'en' as LanguageState,
  reducers: {
    setLanguage: (_, action) => action.payload,
  },
});

type ExperimentsState = ExperimentsBag;
const experimentsSlice = createSlice({
  name: 'experimetns',
  initialState: {} as ExperimentsState,
  reducers: {
    setExperiments: (_, action) => action.payload,
  },
});

type RegionalSettingsState = string;
const regionalSettingsSlice = createSlice({
  name: 'regionalSettings',
  initialState: 'en' as RegionalSettingsState,
  reducers: {
    setRegionalSettings: (_, action) => action.payload,
  },
});

type IdentityParams = {
  appInstanceId: string;
  msid?: string;
  instance: string;
  siteOwnerId: string;
  appDefinitionId: string;
  sessionId: string;
};
const IdentityParamsSlice = createSlice({
  name: 'Identity',
  initialState: { appInstanceId: '', msid: '', instance: '', siteOwnerId: '', appDefinitionId: '', sessionId: '' },
  reducers: {
    setIdentityParams: (_, action) => action.payload,
    setInstance: (_, action) => ({ ..._, instance: action.payload }),
  },
});
type UserState = any;
const userSlice = createSlice({
  name: 'user',
  initialState: null as UserState,
  reducers: {
    setUser: (_, action) => action.payload,
  },
});

export const { setLanguage } = languageSlice.actions;
export const { setRegionalSettings } = regionalSettingsSlice.actions;
export const { setUser } = userSlice.actions;
export const { setIdentityParams, setInstance } = IdentityParamsSlice.actions;
export const { setExperiments } = experimentsSlice.actions;

type AccordionState = any[];
const accordionSlice = createSlice({
  name: 'accordion',
  initialState: [] as AccordionState,
  reducers: {
    open: (state, action) => [...state, action.payload],
    close: (state, action) => state.filter((id) => id !== action.payload),
  },
});

type CancelConfirmModalState = { subscriptionId: null | string; isOpen: boolean };
const cancelConfirmModalSlice = createSlice({
  name: 'cancelConfirmModal',
  initialState: { subscriptionId: null, isOpen: false } as CancelConfirmModalState,
  reducers: {
    open: (state, action) => ({ subscriptionId: action.payload, isOpen: true }),
    close: () => ({ subscriptionId: null, isOpen: false }),
  },
});

export const cancelSubscription = createAsyncThunk<Subscription, string, ThunkApiConfig>(
  'subscriptions/cancel',
  async (subscriptionId, { extra: { fedops, httpClient }, getState, dispatch }) => {
    const state = getState();
    const subscription = getSubscriptionById(state, subscriptionId);

    const billingSubscription = subscription as Subscription;
    let response;
    if (isRecurringSubscription(subscription)) {
      response = await httpClient.request(customerTurnOffSubscriptionAutoRenewal({ id: billingSubscription.id! }));
    } else {
      response = await httpClient.request(customerCancelSubscription({ id: billingSubscription.id! }));
    }
    fedops.interactionEnded(Interactions.SubscriptionCancel);

    await dispatch(fetchSubscriptionDetailsById(subscriptionId));

    return response.data.subscription!;
  },
);
export interface UpmArgs {
  subscriptionId: string;
  paymentMethodId: string;
}

export const submitUpm = createAsyncThunk<void, UpmArgs, ThunkApiConfig>(
  'subscriptions/upm',
  async (
    { subscriptionId, paymentMethodId },
    { extra: { errorMonitor, httpClient, fedops, translations, biLogger }, dispatch, getState },
  ) => {
    try {
      await updatePaymentMethodInBassBackend({
        httpClient,
        subscriptionId,
        paymentMethodId,
        dispatch,
      });
      const { paymentMethodDetails } = getDetails(getState(), subscriptionId);
      dispatch(
        toastSlice.actions.showSuccessToast({
          message: translations.t('app.upm.success.title', {
            paymentMethod: getPaymentMethodDisplayName(
              paymentMethodDetails?.savedCreditCardDetails?.network,
              translations.t,
            ),
            cardNumber: paymentMethodDetails?.savedCreditCardDetails?.lastFourDigits,
          }),
        }),
      );
      setTimeout(() => {
        dispatch(closeToast());
      }, TIME_UNTIL_SUCCESS_TOAST_DISAPPEARS);
      const subscription = getSubscriptionById(getState(), subscriptionId);
      biLogger.report(
        aPaymentMethodUpdatedSuccessfully({
          subscriptionId,
          newPaymentMethodId: subscription.billingSettings?.paymentMethod?.id,
        }),
      );
    } catch (e) {
      onUpmServerError(errorMonitor, e, dispatch, translations);
    }
    dispatch(closeUpmModal());
    dispatch(upmModalSlice.actions.setStatus(UpmFlowStatus.INIT));
    fedops.interactionEnded(Interactions.SubscriptionUpm);
  },
);

const onUpmServerError = (errorMonitor: any, e: unknown, dispatch: Dispatch<any>, translations: any) => {
  errorMonitor.addBreadcrumb({
    message: 'There was a problem with updating the payment method in bass backend ',
  });
  errorMonitor.captureException(e);
  dispatch(toastSlice.actions.showErrorToast({ message: translations.t('app.upm.failure.bass-failure') }));
  setTimeout(() => {
    dispatch(closeToast());
  }, TIME_UNTIL_ERROR_TOAST_DISAPPEARS);
};

const updatePaymentMethodInBassBackend = async ({
  httpClient,
  subscriptionId,
  paymentMethodId,
  dispatch,
}: {
  httpClient: IHttpClient;
  subscriptionId: string;
  paymentMethodId: string;
  dispatch: Dispatch<any>;
}) => {
  const {
    data: { subscription },
  } = await httpClient.request(customerUpdateSubscriptionPaymentMethod({ id: subscriptionId, paymentMethodId }));
  dispatch(subscriptionsSlice.actions.replaceSubscription(subscription));
  await Promise.all([dispatch(fetchSubscriptionDetailsById(subscriptionId))]); //
};

export const openCancelConfirmModal = createAsyncThunk<void, string, ThunkApiConfig>(
  'subscriptions/openCancelConfirmModal',
  async (subscriptionId, { extra: { biLogger, experiments }, getState, dispatch }) => {
    const state = getState();
    const subscription = getSubscriptionById(state, subscriptionId);

    await biLogger.report(
      mySubscriptionsCancelSubscriptionConfirmed({
        action: 'cancel',
        subscriptionId,
        originEntityId: (subscription as Subscription)?.origin?.entityId || undefined,
        appId: (subscription as Subscription)?.origin?.appId || undefined,
      }),
    );

    dispatch(cancelConfirmModalSlice.actions.open(subscriptionId));
  },
);

export const closeCancelConfirmModal = cancelConfirmModalSlice.actions.close;
export const confirmCancel = createAsyncThunk<void, void, ThunkApiConfig>(
  'cancelConfirmModal/confirmCancel',
  async (arg, { extra: { biLogger, experiments }, dispatch, getState }) => {
    const state = getState();
    const subscriptionId = getCancelConfirmModalSubscriptionId(state) as string;
    const subscription = getSubscriptionById(state, subscriptionId);

    await biLogger.report(
      mySubscriptionsCancelSubscriptionConfirmed({
        action: 'cancel-confirm',
        subscriptionId,
        originEntityId: (subscription as Subscription)?.origin?.entityId || undefined,
        appId: (subscription as Subscription)?.origin?.appId || undefined,
      }),
    );

    await dispatch(cancelSubscription(subscriptionId));
    dispatch(closeCancelConfirmModal());
  },
);
export type ToastState = { isShown: boolean; message?: string; isSuccess: boolean };
const toastSlice = createSlice({
  name: 'toast',
  initialState: { isShown: false } as ToastState,
  reducers: {
    showSuccessToast: (_, action) => ({ message: action.payload?.message, isShown: true, isSuccess: true }),
    showErrorToast: (_, action) => ({ message: action.payload?.message, isShown: true, isSuccess: false }),
    closeToast: (state) => ({ ...state, isShown: false }),
  },
});

export const closeToast = toastSlice.actions.closeToast;

type UpmModalState = { subscriptionId: null | string; isOpen: boolean; upmFlowStatus: UpmFlowStatus };
const upmModalSlice = createSlice({
  name: 'upmModal',
  initialState: { subscriptionId: null, isOpen: false, upmFlowStatus: UpmFlowStatus.INIT } as UpmModalState,
  reducers: {
    open: (state, action) => ({ ...state, subscriptionId: action.payload, isOpen: true }),
    close: (state) => ({ ...state, subscriptionId: null, isOpen: false }),
    setStatus: (state, action) => ({ ...state, upmFlowStatus: action.payload }),
  },
});

export const openUpmModal = createAsyncThunk<void, string, ThunkApiConfig>(
  'subscriptions/openUpmModal',
  async (subscriptionId, { extra: { biLogger, experiments }, getState, dispatch }) => {
    dispatch(upmModalSlice.actions.open(subscriptionId));
  },
);
export const setUpmFlowStatus = createAsyncThunk<void, UpmFlowStatus, ThunkApiConfig>(
  'subscriptions/setUpmFlowStatus',
  async (status, { extra: { biLogger, experiments }, getState, dispatch }) => {
    dispatch(upmModalSlice.actions.setStatus(status));
  },
);
export const closeUpmModal = upmModalSlice.actions.close;

export const fetchAllSubscriptions = createAsyncThunk<Subscription[] | undefined, void, ThunkApiConfig>(
  'subscriptions/fetchAll',
  async (_, { extra: { httpClient }, getState }) => {
    const { user } = getState();
    if (!user?.loggedIn) {
      return;
    }
    const {
      data: { subscriptions },
    } = await httpClient.request(customerQuerySubscriptions({}));
    return subscriptions;
  },
);

type SubscriptionsState = { entities: Subscription[]; loading: string };
export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: { entities: [], loading: 'idle' } as SubscriptionsState,
  reducers: {
    demoSubscriptions: (state, action) => ({
      ...state,
      entities: action.payload,
    }),
    replaceSubscription: (state, action) => {
      return {
        ...state,
        entities: state.entities.map((subscription) =>
          subscription.id === action.payload.id ? action.payload : subscription,
        ),
      };
    },
  },
  extraReducers: {
    [fetchAllSubscriptions.pending.type]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    [fetchAllSubscriptions.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.entities.push(...action.payload);
      }
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },
    [fetchAllSubscriptions.rejected.type]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },
    [cancelSubscription.fulfilled.type]: (state, action) => {
      const idx = state.entities.findIndex((s: any) => s.id === action.payload.id);
      if (idx > -1) {
        state.entities[idx] = action.payload;
      }
    },
  },
});

const fetchSubscriptionDetailsById = createAsyncThunk<any, string, ThunkApiConfig>(
  'subscriptionDetails/fetchById',
  async (
    subscriptionId,
    {
      extra: { baseUrl, httpClient, errorMonitor, ecomSubscriptionsService, memberBenefitsService, experiments },
      getState,
    },
  ) => {
    const state = getState();
    const { user } = state;
    const subscription = getSubscriptionById(state, subscriptionId);

    if (!user?.loggedIn) {
      return;
    }

    const headers = { Authorization: user.instance };
    const billingSubscription = subscription as Subscription;
    const shouldShowLastNextCharge: boolean =
      isFeatureEnabled(state, Experiments.SHOW_LAST_NEXT_CHARGE) &&
      subscription?.bassManaged &&
      hasAnyDiscount(subscription) &&
      !!subscription?.billingSettings?.currency;
    const shouldShowPaymentMethodDetails = !!(
      isFeatureEnabled(state, Experiments.ENABLE_UPDATE_PAYMENT_METHOD) &&
      billingSubscription.billingSettings?.paymentMethod?.id
    );

    const promiseListUpcomingCharges: Promise<SubscriptionCharge | undefined> | undefined =
      shouldShowLastNextCharge && subscription?.billingStatus?.nextBillingDate
        ? httpClient
            .request(customerListUpcomingCharges({ id: billingSubscription.id! }))
            .then((response) => response.data)
            .then((data) => data?.upcomingCharge)
            .catch(() => undefined)
        : undefined;

    const promisePaymentMethodDetails = shouldShowPaymentMethodDetails
      ? httpClient
          .request(
            getSavedPaymentMethodDetailsByAgreementId({
              paymentAgreementId: billingSubscription.billingSettings?.paymentMethod?.id!,
            }),
          )
          .then((response) => response?.data?.savedPaymentMethodDetails)
          .catch((e) => {
            errorMonitor.addBreadcrumb({
              message: 'error when trying to get payment method details to show for subscription',
            });
            errorMonitor.captureException(e);
            return undefined;
          })
      : undefined;

    const promiseAllowedActions = httpClient
      .request(customerAllowedActions({ id: billingSubscription.id! }))
      .then((response) => response.data.actions)
      .catch(() => []);
    let promiseBalanceItems: Promise<any> = Promise.resolve(undefined);
    let promiseStoresSubscription: Promise<any> = Promise.resolve(undefined);

    if (billingSubscription.origin?.appId === PAID_PLANS_APP_DEF_ID) {
      promiseBalanceItems = memberBenefitsService(headers)
        .getBalance({
          contactId: billingSubscription.customer?.contactId || billingSubscription.customer?.memberId,
          planOrderIds: [billingSubscription.origin?.entityId!],
        })
        .then((response) => response.balanceItems)
        .catch(() => undefined);
    }

    if (billingSubscription.origin?.appId === STORES_APP_DEF_ID) {
      promiseStoresSubscription = ecomSubscriptionsService(headers)
        .getSubscription({
          id: billingSubscription?.origin?.entityId ?? undefined,
        })
        .then((response) => response.subscription)
        .catch(() => undefined);
    }

    const [allowedActions, benefitBalanceItems, storesSubscription, nextCharge, paymentMethodDetails] =
      await Promise.all([
        promiseAllowedActions,
        promiseBalanceItems,
        promiseStoresSubscription,
        promiseListUpcomingCharges,
        promisePaymentMethodDetails,
      ]);

    return {
      allowedActions,
      benefitBalanceItems,
      storesSubscription,
      shouldShowLastNextCharge,
      nextCharge,
      paymentMethodDetails,
    };
  },
);

export const openDetails =
  (subscriptionId: string): Thunk =>
  async (dispatch, getState, { biLogger }) => {
    const subscription = getSubscriptionById(getState(), subscriptionId);
    dispatch(accordionSlice.actions.open(subscriptionId));

    if (!subscriptionId.includes('mock')) {
      biLogger.report(
        mySubscriptionsShowDetails({
          subscriptionId,
          subscriptionStatus: (subscription as Subscription).status,
        }),
      );
      await dispatch(fetchSubscriptionDetailsById(subscriptionId));
    }
  };
export const demoSubscriptions = (): Thunk => (dispatch, getState) => {
  dispatch(subscriptionsSlice.actions.demoSubscriptions(subscriptionsBassFixture));
  const shouldShowPaymentMethodDetails = !!isFeatureEnabled(getState(), Experiments.ENABLE_UPDATE_PAYMENT_METHOD);
  dispatch(
    detailsSlice.actions.mockDetails({
      ...getEditorSubscriptionDetailsDemo(shouldShowPaymentMethodDetails),
      id: subscriptionsBassFixture[0].id,
    }),
  );
  dispatch(openDetails(subscriptionsBassFixture[0].id!));
};

export const closeDetails = accordionSlice.actions.close;

export type Details = {
  allowedActions?: Action[];
  benefitBalanceItems?: Balance[];
  storesSubscription?: StoresSubscription;
  paymentSubscriptionInfo?: any;
  nextCharge?: SubscriptionCharge;
  shouldShowLastNextCharge?: boolean;
  paymentMethodDetails?: SavedPaymentMethodDetails | undefined;
};
type DetailsState = { entities: { [key: string]: Details }; loading: any[] };
const detailsSlice = createSlice({
  name: 'details',
  initialState: { entities: {}, loading: [] } as DetailsState,
  reducers: {
    mockDetails: (state, action) => ({
      ...state,
      entities: { ...state.entities, [action.payload.id]: action.payload },
    }),
  },
  extraReducers: {
    [accordionSlice.actions.open.type]: (state, action) => {
      state.loading.push(action.payload);
    },
    [fetchSubscriptionDetailsById.fulfilled.type]: (state, action) => {
      const subscriptionId = action.meta.arg;
      // @ts-expect-error
      state.entities[subscriptionId] = action.payload;
      state.loading = state.loading.filter((id) => id !== subscriptionId);
    },
    [fetchSubscriptionDetailsById.rejected.type]: (state, action) => {
      state.loading = state.loading.filter((id) => id !== action.meta.arg);
    },
  },
});

export interface RootState {
  cancelConfirmModal: CancelConfirmModalState;
  upmModal: UpmModalState;
  language: LanguageState;
  regionalSettings: RegionalSettingsState;
  accordion: AccordionState;
  subscriptions: SubscriptionsState;
  details: DetailsState;
  user: UserState;
  IdentityParams: IdentityParams;
  toast: ToastState;
  experiments: ExperimentsState;
}

const rootReducer = combineReducers({
  cancelConfirmModal: cancelConfirmModalSlice.reducer,
  language: languageSlice.reducer,
  regionalSettings: regionalSettingsSlice.reducer,
  accordion: accordionSlice.reducer,
  subscriptions: subscriptionsSlice.reducer,
  details: detailsSlice.reducer,
  user: userSlice.reducer,
  upmModal: upmModalSlice.reducer,
  IdentityParams: IdentityParamsSlice.reducer,
  toast: toastSlice.reducer,
  experiments: experimentsSlice.reducer,
});

export default rootReducer;
